import GraphiQL from 'graphiql'
import { GraphQLSchema } from 'graphql'
import React from 'react'
import { generateTrackingId } from '../../utils/general.utils'

interface IGraphiQLPlayGround {
  token: string
  url: string
  updateCurl: Function
  schema?: GraphQLSchema | null
}

const GraphiQLPlayGround: React.FC<IGraphiQLPlayGround> = ({
  token,
  url,
  updateCurl,
  schema,
}) => {
  const defaultQuery = `# Welcome to GraphiQL
#
# GraphiQL is an in-browser tool for writing, validating, and
# testing GraphQL queries.
#
# Type queries into this side of the screen, and you will see intelligent
# typeaheads aware of the current GraphQL type schema and live syntax and
# validation errors highlighted within the text.
#
# GraphQL queries typically start with a "{" character. Lines that start
# with a # are ignored.
#
# An example GraphQL query might look like:
#
#     {
#       field(arg: "value") {
#         subField
#       }
#     }
#
# Keyboard shortcuts:
#
#  Prettify Query:  Shift-Ctrl-P
#
#  Merge Query:  Shift-Ctrl-M
#
#  Run Query:  Ctrl-Enter (or press the play button above)
#
#  Auto Complete:  Ctrl-Space (or just start typing)
#
`

  const fetcher = (graphQLParams: any): any => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      trackingId: generateTrackingId(),
    }
    const postBody = JSON.stringify(graphQLParams)
    // using fetch since axios doesnt work well with graphiql
    return fetch(url, {
      method: 'post',
      headers: headers,
      body: postBody,
    }).then((response) => response.json())
  }

  return (
    <GraphiQL
      fetcher={fetcher}
      editorTheme="dracula"
      defaultQuery={defaultQuery}
      onEditQuery={updateCurl}
      schema={schema}
    >
      <GraphiQL.Logo> </GraphiQL.Logo>
      <GraphiQL.Toolbar></GraphiQL.Toolbar>
    </GraphiQL>
  )
}

export default GraphiQLPlayGround
